@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);

body {
    font-family: 'Nunito', Helvetica, Arial, sans-serif;
}

/* html {
    overflow-y: visible;
} */

.MuiListItem-root .NamedLink_active {
    border-left-style: solid;
    border-left-width: 6px;
    border-left-color: var(--marketplaceColor);
}

.MuiListItem-root .NamedLink_active{ 
    font-weight: var(--fontWeightMedium);
}

.MuiPopover-paper {
    margin-top: 50px;
}

.MuiAccordion-root:before {
    background-color: transparent !important;
}

/* .MuiStepIcon-active {
    color: red !important;   
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px rgba(255,255,255,0) inset !important;
 /* background-color: rgba(255,255,255,0);
 border-color: rgba(255,255,255,0); */
}
