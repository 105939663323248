@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  /* display: flex; */


  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    /* display: flex; */
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  /* width: 100vw;
  height: 100vh; */
  margin: 0 auto;

  @media (--viewportMedium) {
    margin-left: 20px;
  }
}

.map {

  height: 100vh;

  @media (--viewportMedium) {
    width: 100vw;
    position: sticky;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 100%;
  }
}


/********************************* NEW SEARCH PAGE **********************************/
.root {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 70px;


  @media (--viewportMedium) {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}

.buttonText {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.buttonTextActive {
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
}

.categoryActive {
  border: none;
  border-radius: var(--borderRadius);
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;
  text-decoration: none;
  transition: all var(--transitionStyleButton);
  width: 100%;
  font-size: 16px;
}

.categoryActive span {
  font-weight: 700;

}

.buttonCategory:first-child {

  @media (--viewportMedium) {
    margin-top: 50px;
  }
}

.buttonCategory {
  margin-bottom: 0px;
  background-color: var(--matterColorFilter);
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  width: 100%;
  padding: 10px;
  font-size: 16px;

  @media (--viewportMedium) {
    margin-bottom: 10px;
    padding: 0px;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorRollover);

  }
}

.buttonCategoryActive {
  margin-bottom: 0px;
  background-color: var(--marketplaceColor);
  color: var(--matterColorNegative);

  width: 100%;
  padding: 10px;

  @media (--viewportMedium) {
    margin-bottom: 10px;
    padding: 0px;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorRollover);
    color: var(--matterColor);
  }
}

.categoryFilterForMobile {
  display: flex;
  align-items: center;
}

.mobileButtonCategory {
  margin-bottom: 0px;
  color: var(--matterColor);
  background-color: transparent;
  font-weight: var(--fontWeightMedium);
  width: 100%;
  padding: 10px;
  font-size: 12px;

  &:hover,
  &:focus {
    background-color: transparent;
  }

}

.mobileButtonCategoryActive {
  margin-bottom: 0px;
  color: var(--matterColor);
  background-color: transparent;
  width: 100%;
  padding: 10px;
  font-size: 12px;

  &:hover,
  &:focus {
    background-color: transparent;
  }

}

.selectedCategoryTextForMobile {
  width: 60%;
  text-align: center;
  margin: auto;
  display: block;
  height: 1px;
  border-color: var(--marketplaceColor);
  border-top-style: solid;
}


.categoryTextForMobile {
  width: 60%;
  text-align: center;
  margin: auto;
  display: block;
  height: 1px;
  border: solid;
  border-color: transparent
}


.filterWrapper {
  display: none;
  justify-content: flex-start;

  @media (min-width: 903px) {
    display: flex;
    align-items: center;
  }

}


.buttonFilter {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  @media (--viewportMedium) {
    @apply --marketPlaceButtonStyleFilter;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
    color: var(--matterColor);
  }
}


/**----------------------------- FILER ADDRESS ------------------------------------ */
.FilterPopup label {
  min-width: 300px !important;
}

.locationRoot {
  border: .5px solid var(--matterColorFilter);
  background-color: white !important;
  border-radius: 50px;
  margin-right: 10px;
  height: 36px;
  padding-left: 12px;
}

.locationRootForMobile {
  border: .5px solid #bbbbbb;
  background-color: white !important;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 36px;
  padding-left: 12px;
}

.locationRootForMobile input {
  border: none;
  border-radius: 50px;
  height: 36px;
  background-color: transparent;
}


.locationRootForMobile svg path,
.locationRootForMobile svg g {
  stroke: var(--matterColorAnti);
}

.locationRoot input {
  border: none;
  border-radius: 50px;
  height: 36px;
  background-color: transparent;
}


.locationRoot svg path,
.locationRoot svg g {
  stroke: var(--matterColorAnti);
}

.locationAutocompleteInputIcon svg {
  height: 14px;
  margin-right: 10px;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  /* background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius); */
  box-shadow: var(--boxShadowPopup);
  z-index: 9999;

  @media (--viewportMedium) {
    top: 40px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

/****************************************************/
.SectionNoResultContainer {}

.SectionNoResultMessageWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: var(--matterColorFilter);
  border-radius: 6px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    max-width: 60%;
  }
}

/****************FILTER **********************/

.filterButtonWrapper {
  position: fixed;
  background-color: var(--matterColorFilter);
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 5px 0px 5px 0px;
  display: flex;
  justify-content: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.filterButtonWrapper div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.filterButtonWrapper div span {
  font-size: 11px;
  margin-top: -5px;
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;
  overflow-y: auto;
  min-width: 250px;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    overflow-y: unset;
    min-width: 350px;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hideMobile {
  display: none;

  @media (min-width: 903px) {
    display: block;
  }
}

.hideDesktop {
  display: block;

  @media (min-width: 903px) {
    display: none;
  }
}

.modalContainer {
  margin-top: -30px;

  @media (--viewportMedium) {
    margin-top: 0px;
    padding-top: 50px;
  }
}

.validateButton {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.validateButton button {
  width: 180px;
}

.singleDatePicker {
  height: 35px;
  background-color: white;
  border: 1px solid var(--matterColorFilter);
  margin-right: 20px;
  border-radius: 130px;
  z-index: 9;
}

/* .singleDatePicker input {
    height: 35px;
    padding: 0px;
} */

.linkReset {
  color: var(--matterColor);
  text-decoration: underline;
  cursor: pointer;
}

.linkResetMobile {
  color: var(--matterColor);
  text-decoration: underline;
  cursor: pointer;
  text-align: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.openFilterButton {
  display: block;
  background-color: var(--matterColorFilter) !important;
  color: var(--matterColor) !important;
  padding: none;
  height: 40px;

  @media (--viewportMedium) {
    display: none !important;
  }
}

.formDate {
  z-index: 9999;
}